<template src='./activityQuickTest.html'></template>

<script>
import inputContainer from '../../Components/inputContainer'
import smallButton from '../../Components/smallButton'
import AssignmentUserRepository from '../../Repository/AssignmentUser'
import AssignmentRepository from '../../Repository/Assignment'
import ExamRepository from '../../Repository/Exam'
import ExamSubjectRepository from '../../Repository/ExamSubject'
import ExamTimetableRepository from '../../Repository/ExamTimetable'
import axios from "axios";
import apiV3 from '../../NetworkManager/apiV3'
import showStatus from '../../NetworkManager/showStatus'
import { addMinutes, isAfter, isWithinInterval } from 'date-fns'

export default {
  name: 'activityQuickTest',
  components: {
    inputContainer,
    smallButton
  },
  props: [
    'prop_subject', 'prop_type', 'prop_groupInfo'
  ],
  data() {
    return {
      questionBankRedirectionUrl: true,
      isMobileView: false,
      tempTitle: '',
      tempDescription: '',
      tempMarks: 0,
      tempDueDate: '',
      pastAssignments: [],
      currentAssignments: [],
      progressUpload: 0,
      isEklavvyaIntegration: 0,
      exams: [],
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Date (dd/mm/yyyy, hh:MM:ss)',
          value: 'dateTimeUTC'
        },
        {
          text: 'Duration (minutes)',
          value: 'durationInMins'
        },
        {
          text: 'Status',
          value: 'status'
        },
        /* { */
        /*   text: 'Edit', */
        /*   value: 'edit' */
        /* }, */
        {
          text: 'Analysis',
          value: 'exam'
        }
      ],
      EkExams: [],
      EkHeaders: [
        {
          text: 'Test Name',
          value: 'ExamName'
        },
        {
          text: 'StartDate (dd/mm/yyyy)',
          value: 'ScheduleStartDate'
        },
        {
          text: 'EndDate (dd/mm/yyyy)',
          value: 'ScheduleEndDate'
        },
        {
          text: 'Duration (minutes)',
          value: 'ExamDuration'
        },
        {
          text: 'ScheduleDate',
          value: 'ScheduleStartDate'
        },
        {
          text: 'Status',
          value: 'Status'
        },
        {
          text: 'Schedule',
          value: 'Schedule'
        }

      ],
      loading: false,
      selectedSubject: {},
      instituteConfig: {},
      isLoadingExams: false,
      isDeleteDialogOpen: false,
      selectedExam: null,
      isDeletingExam: false,
      dialogForCard: false,
      dialogTitle: '',
      dialogInfo: [],
      examDetailsObject: {}
      // openWindow: false
    }
  },
  created() {
    this.$store.commit('liveData/set_selectedActivityName', 'Quick Test')
    this.isMobileView = this.$store.getters['liveData/get_isMobileView']
    this.instituteConfig = this.$store.getters['instituteData/get_config']
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.get_applicableInstitutes = this.$store.getters['instituteData/get_applicableInstitutes']
    this.selectedSemester = this.$store.getters['instituteData/get_selectedSemester']
    this.userData = this.$store.getters['user/get_userData']
    this.examSubjectRepositoryInstance = new ExamSubjectRepository(this)
    this.assignmentRepositoryInstance = new AssignmentRepository(this)
    this.assignmentUserRepositoryInstance = new AssignmentUserRepository(this)
    this.examRepositoryInstance = new ExamRepository(this)
    this.examTimetableInstance = new ExamTimetableRepository(this)
    // console.log('this.get_applicableInstitutes[0]', this.get_applicableInstitutes[0]);
    axios.defaults.headers.common['apiKey'] = this.get_applicableInstitutes[0].instituteId;
    this.isEklavvyaIntegration = this.get_applicableInstitutes[0].isEklavvyaIntegration;
    //this.isEklavvyaIntegration = 1;
    if (this.prop_subject) {
      this.selectedSubject = JSON.parse(JSON.stringify(this.prop_subject))
    } else {
      this.selectedSubject = JSON.parse(JSON.stringify(this.$store.getters['liveData/get_selectedSubject']))
    }
    this.pastAssignments = []
    this.currentAssignments = []
    if (this.isEklavvyaIntegration === 1) {
      this.SyncDataToEklavya();
    } else if (!this.isEklavvyaIntegration && this.instituteConfig.exams === "IN_HOUSE") {
      this.getExams()
    } else {
      throw new Error('Unknown condition in exams module')
    }
  },
  methods: {
    openDialog(data) {
      this.dialogForCard = true
      console.log('data', data)
      this.examDetailsObject = {
        ...data
      }
      // this.dialogTitle = data.cardTitle
      // this.dialogInfo = data.cardDescription
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    async SyncDataToEklavya() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId
        }
        this.tempExams = await this.examRepositoryInstance.SyncDataToEklavya(objToPush) || []
        this.getEkExams();
      } catch (err) {
        console.log(err)
        this.exams = []
      }
    },
    async getExams() {
      try {
        this.isLoadingExams = true
        const { exams = [] } = await apiV3.getRequest('/exam/all', {
          category: 'quick_exam',
          facultyId: this.userData.uId,
          subjectId: this.selectedSubject.subjectId,
          semId: this.selectedSemester.semId
        })
        // console.log('exams', exams)
        if (this.prop_type === 'Group') {
          this.exams = exams.filter(i => (i.assignedGroupForSubject && i.assignedGroupForSubject.groupId === this.prop_groupInfo.groupId) || (!i.assignedGroupForSubject && !i.division));
        }
        if (this.prop_type === 'Division') {
          this.exams = exams.filter(i => (i.division === this.prop_subject.division) || (!i.assignedGroupForSubject && !i.division))
        }
        if (this.prop_type === 'Batch') {
          this.exams = exams.filter(i => (i.division === this.prop_subject.division) && (i.batch === this.prop_subject.batch))
        }
        // this.exams = exams
      } catch (err) {
        alert("An error occured fetching exams")
        console.error(err)
      } finally {
        this.isLoadingExams = false
      }
    },
    async getEkExams() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          subjectId: this.selectedSubject.subjectId
        }
        this.tempExams = await this.examRepositoryInstance.getEkAllExams(objToPush) || []
        this.exams = this.tempExams.results
        console.log('this is exam finally', this.exams)
      } catch (err) {
        console.log(err)
        this.exams = []
      }
    },
    gotoCreateTest(subject) {
      this.$router.push({
        name: 'activityCreateTest',
        params: {
          prop_subject: subject,
          prop_type: this.prop_type,
          prop_groupInfo: this.prop_groupInfo
        }
      })
    },
    async gotoUploadQuestionBank() {
      if (this.questionBankRedirectionUrl) {
        try {
          const email = await this.examTimetableInstance({
            request: { request: this.get_applicableInstitutes[0].instituteEmail }
          })
          const authKey = await this.examTimetableInstance.getExamEncryptionKey({
            request: { request: this.get_applicableInstitutes[0].instituteId }
          })
          var url = `https://app.eklavvya.com/InstituteAdmin/Authenticate.aspx?
            EmailID=${email.results}&AuthenticationKey=${authKey.results}&Page=U5NkLz4Zh+bp4UTeZbaQeZ5aAFZ/dXlfr3VJmbtr5lVi8pxK3dNNZwiqjuZ7l9ut`;
          console.log('url', url);
          window.open(url, '_blank');
        } catch (err) {
          console.log(err)
        }
      }
    },
    async gotoEkCreateTest() {
      if (this.questionBankRedirectionUrl) {
        try {
          console.log('this.get_applicableInstitutes[0]', this.get_applicableInstitutes[0]);
          const email = await this.examTimetableInstance.getExamEncryptionKey({
            request: { request: this.get_applicableInstitutes[0].instituteEmail },
            instituteId: this.get_applicableInstitutes[0].instituteId
          })
          const authKey = await this.examTimetableInstance.getExamEncryptionKey({
            request: { request: this.get_applicableInstitutes[0].instituteId },
            instituteId: this.get_applicableInstitutes[0].instituteId
          })
          // console.log("this.get_applicableInstitutes[0]", this.get_applicableInstitutes[0].instituteEmail)
          var url = "https://app.eklavvya.com/InstituteAdmin/Authenticate.aspx?" +
            "EmailID=" + email.results + "" +
            "&AuthenticationKey=" + authKey.results + "" +
            "&Page=+x49wiHdKw/qF0RY/Vl/CG9dxrYp/AnsENG2fBd4I2s=";
          window.open(url, '_blank');
        } catch (err) {
          console.log(err)
        }
      }
    },
    async gotoSheduleExam() {
      if (this.questionBankRedirectionUrl) {
        try {
          const email = await this.examTimetableInstance.getExamEncryptionKey({
            request: { request: this.get_applicableInstitutes[0].instituteEmail }
          })
          const authKey = await this.examTimetableInstance.getExamEncryptionKey({
            request: { request: this.get_applicableInstitutes[0].examApiKey }
          })
          var url = "https://app.eklavvya.com/InstituteAdmin/Authenticate.aspx?" +
            "EmailID=" + email.results + "" +
            "&AuthenticationKey=" + authKey.results + "" +
            "&Page=haEQoeMJrXefy21fj3VPIINgKX/765puQ9BBXACJ0C9pLEjzqy+WaJgk9uFom9BV"
          // console.log("")
          window.open(url, '_blank');
        } catch (err) {
          console.log(err)
        }
      }
    },
    gotoQuickTestInsight(exam) {
      this.$store.commit('liveData/set_selectedExam', exam)
      this.$router.push({
        name: 'activityQuickTestInsight'
        // params: {
        //   prop_subject: subject
        // }
      })
    },
    checkItem(exam) {
      // console.log(exam)
      this.$store.commit('liveData/set_selectedExam', exam)
      this.$router.push({
        name: 'activityCreateTest',
        params: {
          prop_selectedTest: exam
        }
      })
    },
    async deleteExam() {
      try {
        this.isDeletingExam = true
        const exam = this.selectedExam
        await apiV3.deleteRequest('/exam/delete', {
          examId: exam._id
        })
        this.exams = []
        this.selectedExam = null
        this.isDeleteDialogOpen = false
        this.getExams()
        showStatus('Exam deleted successfully', 3000, 'success', this)
      } catch (err) {
        showStatus('An error occured, try again later', 3000, 'error', this)
      } finally {
        this.isDeletingExam = false
      }
    },
    getExamStatus(exam) {
      const now = new Date()
      const examStart = new Date(exam.dateTimeUTC)
      const examEnd = addMinutes(new Date(exam.dateTimeUTC), exam.durationInMins + 2)
      if (isWithinInterval(now, { start: examStart, end: examEnd })) {
        return 'ongoing'
      } else if (isAfter(now, examEnd)) {
        return 'conducted'
      } else {
        return 'published'
      }
    },
    handleGoBack() {
      history.back()
    },
    gotoMySubjects() {
      this.$router.push({
        name: "activityMySubjects",
        params: {
          prop_subject: this.prop_subject,
          prop_type: this.prop_type,
          prop_groupInfo: this.prop_groupInfo
        }
      });
    }
  }
}
</script>

<style src='./activityQuickTest.css'></style>
