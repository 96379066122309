import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/Shift'

class Shift {
  constructor (context) {
    this.context = context
  }

  async getShift (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getShift, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.shift
  }
}

export default Shift
