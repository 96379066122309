export default {
  arrangeALecture: '/lectures/arrangeLectures',
  createMultipleLectures: '/lectures/createMultipleLectures',
  updateMultipleLectureDocument: '/lectures/updateMultipleLectureDocument',
  lectureNotification: '/lectures/lectureNotification',
  getLecture: '/lectures/getLectureById',
  deleteLecture: '/lectures/deleteLecture',
  getLecturesBySubject: '/lectures/getLecturesBySubject',
  facultyLectureClashDetection: '/lectures/facultyLectureClashDetection',
  roomClashDetection: '/lectures/roomClashDetection',
  getAllLectureOfADivision: '/lectures/getAllLectureOfADivision',
  getLecturesOfADivisionForADay: '/lectures/getLecturesOfADivisionForADay',
  getLecturesOfAFacultyOfASubject: '/lectures/getLecturesOfAFacultyOfASubject',
  getLecturesForDateForAssignedSubjects: '/lectures/getLecturesForDateForAssignedSubjects',
  getAllLecturesOfMultipleInstitutesPerDayByUId: '/lectures/getAllLecturesOfMultipleInstitutesPerDayByUId',
  getLecturesOfAFacultyOfASubjectOfADivision: '/lectures/getLecturesOfAFacultyOfASubjectOfADivision',
  getLecturesForADay: '/lectures/getLecturesForADayForAFaculty',
  updateLecturerById: '/lectures/updateLecturerById',
  updateLectureRecordingLinkById: '/lectures/updateLectureRecordingLinkById',
  getFreeFacultiesForALecture: '/lectures/getFreeFacultiesForALecture',
  saveAttendance: '/lectures/markAttendance',
  getTotalAttendedLecturesForADivisionForSubject: '/lectures/getTotalAttendedLecturesForADivisionForSubject',
  getConductedLecturesCountOfASubject: '/lectures/getConductedLecturesCountOfASubject',
  getAttendanceAnalysisForADivisionForASubject: '/lectures/getAttendanceAnalysisForADivisionForASubject',
  getAttendanceAnalysisForADivision: '/lectures/getAttendanceAnalysisForADivision',
  cancelALecture: '/lectures/cancelALecture',
  updateRemarksForLecture: '/lectures/updateRemarksForLecture',
  getLecturesForADayForAFaculty: '/lectures/getLecturesForADayForAFaculty',
  generateExcelAttendanceAnalysisDivisionWise: '/excel/lecture/generateExcelAttendanceAnalysisDivisionWise',
  generateExcelSubjectWise: '/excel/lecture/generateExcelSubjectWise',
  generateAttendanceAnalysisExcel: '/excel/lecture/attendance',
  generateAttendanceSheetExcel: '/excel/lecture/attendanceTemplate',
  generateCumulativeAttendanceExcel: '/excel/lecture/generateCumulativeAttendanceExcel',
  getSignedUrl: "/spaces/presigned_url",
  generateExcelForCreateLectureAndAttendance: '/excel/lecture/generateExcelForCreateLectureAndAttendance',
  getPreviousLecForFacForDivForSub: '/lectures/getPreviousLecForFacForDivForSub'
}
