<template src="./activityTakeAttendance.html"></template>
<script>
import UserRepository from "../../Repository/User";
import LectureRepository from "../../Repository/lectures";
import TopicRepository from "../../Repository/Topic";
import SemesterUserRepository from "../../Repository/SemesterUser";
import OnlineLectureRepository from "../../Repository/OnlineLecture";
import showStatus from "../../NetworkManager/showStatus";
import getNamesOfUsers from "../../Services/Utils/getNamesOfUsers";
import ZoomMeetingRepository from "../../Repository/zoomMeeting";
import apiV3 from "../../NetworkManager/apiV3";
import { v4 as uuidv4 } from "uuid";
// import networkManager from '../../NetworkManager/index'
// QR Code Geneartion
import QrcodeVue from "qrcode.vue";
// import SocketioService from '../../Services/Socket/socket.service';
// import firebase from 'firebase'
import auth from "../../Services/Firebase/auth";

export default {
  name: "activityTakeAttendance",
  props: ["prop_lecture"],
  data() {
    return {
      proxyDialog: false,
      loadingQrBtn: false,
      dialog: false,
      backBtnDialog: false,
      markAttendance: false,
      attendance: [],
      proxyStudentDetails: [],
      selection: [],
      totalConductedLectures: 0,
      totalConductedLecturesForGroups: 0,
      topicsOfLecture: [],
      attendanceForGroup: [],
      showTopicsOfLecture: false,
      loading: false,
      headers: [
        {
          text: "Roll Number",
          value: "rollNumber",
          width: "100px"
        },
        {
          text: "Name",
          value: "name"
        },
        {
          text: "Remarks",
          value: "remarkForPresentStudents"
        },
        {
          text: "Total Attended",
          value: "totalAttendedLectures"
        },
        {
          text: "Total Lectures",
          value: "totalConductedLectures"
        },
        {
          text: "Percentage",
          value: "percentage"
        }
      ],
      headers2: [
        {
          text: "Name",
          value: "name"
        },
        {
          text: "Duration",
          value: "duration"
        }
      ],
      headersMobile: [
        {
          text: "Roll Number",
          value: "rollNumber",
          width: "100px"
        },
        {
          text: "Name",
          value: "name"
        }
      ],
      lectureDuration: 0,
      showOnlineAttendance: false,
      isMobileView: false,
      onlineStudents: [],
      studentsMapping: {},
      presentStudentsList: [],
      threshold: 0,
      ex3: { label: "Set Limit", color: "red" },
      isLoadingData: false,
      qrCodeData: "",
      qrCodeSize: 500,
      mobileViewQrCodeSize: 250,
      showQrCode: false,
      qrUpdateIntervalId: "",
      qrGetUpdateDataIntervalId: "",
      applicableStd: [],
      groupStudentsForAttendence: [],
      totalLecturesForGroups: [],
      IsgroupedLecture: false,
      userName: '',
      startTimeLocalString: '',
      endTimeLocalString: ''
    };
  },
  components: {
    QrcodeVue
  },
  created() {
    // console.log("this.prop_lecture", this.prop_lecture);
    this.$store.commit("liveData/set_studentAttendance", []);
    this.$store.commit("liveData/set_proxyData", []);
    this.userName = this.$store.getters['user/get_userName']
    if (this.prop_lecture) {
      // auth.deleteDocumentFromFirebase(this.prop_lecture.lectureId);
    }
    this.isMobileView = this.$store.getters["liveData/get_isMobileView"];

    const startTimeArr = this.prop_lecture.startTime.split("_");
    const startTime =
      parseInt(startTimeArr[0]) * 60 + parseInt(startTimeArr[1]);
    const endTimeArr = this.prop_lecture.endTime.split("_");
    const endTime = parseInt(endTimeArr[0]) * 60 + parseInt(endTimeArr[1]);

    const startTimeUTC = new Date(this.prop_lecture.startTime);
    this.startTimeLocalString = startTimeUTC.toLocaleTimeString('en-US', { timeStyle: 'medium' });
    const endTimeUTC = new Date(this.prop_lecture.endTime);
    this.endTimeLocalString = endTimeUTC.toLocaleTimeString('en-US', { timeStyle: 'medium' });

    this.lectureDuration = endTime - startTime;
    this.threshold = this.lectureDuration * 0.8;
    this.userRepositoryInstance = new UserRepository(this);
    this.lectureRepositoryInstance = new LectureRepository(this);
    this.topicRepositoryInstance = new TopicRepository(this);
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.onlineLectureRepositoryInstance = new OnlineLectureRepository(this);
    this.zoomMeetingInstance = new ZoomMeetingRepository(this);
    this.userData = this.$store.getters["user/get_userData"];
    this.$store.commit("liveData/set_selectedActivityName", "Mark Attendance");
    this.fetchSemesterUsers();
    // console.log('hello uuid', uuidv4());
    // this.SocketioServiceInstance = new SocketioService(this)
  },
  beforeUnmount() {
    this.SocketioServiceInstance.disconnect();
  },
  methods: {
    async backupQrData() {
      // console.log('this.prop_lecture', this.prop_lecture);
      this.prop_lecture.facultyName = this.userName
      await auth.qrCodeAttendanceDataTransferToBackup(this.prop_lecture)
    },
    async gotoSchedule() {
      this.$router.push({
        name: "activityMyTimetable"
      });
    },
    formatDate(dateTime) {
      const date = new Date(dateTime);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${day}-${month}-${year}`;
    },
    async fetchSemesterUsers() {
      this.isLoadingData = true;
      // console.log("this.prop_lecture.division", this.prop_lecture.division);
      if (this.prop_lecture.groupForSubject.length > 0) this.IsgroupedLecture = true
      if (!this.IsgroupedLecture) {
        if (this.prop_lecture.division !== "") {
          const objToPush = {
            instituteId: this.prop_lecture.instituteId,
            semId: this.prop_lecture.semId,
            department: this.prop_lecture.department,
            courseYear: this.prop_lecture.courseYear,
            division: this.prop_lecture.division,
            batch: this.prop_lecture.batch || "",
            subjectId: this.prop_lecture.subjectId,
            uId: this.userData.uId
          };
          try {
            // console.log(objToPush)
            this.attendance =
              await this.semesterUserRepositoryInstance.getSemesterUsersOfASubjectOfADivision(
                objToPush
              );
            if (this.prop_lecture.batch !== undefined && this.prop_lecture.batch !== '') {
              const dummy = this.attendance.filter((stu) => stu.batch === this.prop_lecture.batch)
              this.attendance = dummy
            }
            for (let i = 0; i < this.attendance.length; i++) {
              for (let j = 0; j < this.prop_lecture?.remarkForPresentStudents?.length; j++) {
                if (this.attendance[i].uId === this.prop_lecture?.remarkForPresentStudents[j].uId) {
                  this.attendance[i].remarkForPresentStudents = this.prop_lecture?.remarkForPresentStudents[j].remark
                }
              }
            }
            // console.log('this.attendance111', this.attendance)
            this.attendance.forEach((student) => {
              this.studentsMapping[student.uId] = student;
              this.applicableStd.push(student.uId);
            });
            this.attendance.sort((a, b) => a.rollNumber - b.rollNumber)
          } catch (err) {
            console.log(err);
            this.attendance = [];
          }
        } else {
          let promiseForData = [];
          if (this.prop_lecture.multiDivision.length > 0) {
            promiseForData = this.prop_lecture.multiDivision.map(
              async (data) => {
                const objToPush = {
                  instituteId: this.prop_lecture.instituteId,
                  semId: this.prop_lecture.semId,
                  department: this.prop_lecture.department,
                  courseYear: this.prop_lecture.courseYear,
                  division: data,
                  batch: this.prop_lecture.batch || "",
                  subjectId: this.prop_lecture.subjectId,
                  uId: this.userData.uId
                };
                // console.log("objToPush", objToPush);

                try {
                  const res =
                    await this.semesterUserRepositoryInstance.getSemesterUsersOfASubjectOfADivision(
                      objToPush
                    );
                  return res;
                } catch (err) {
                  console.log(err);
                  this.attendance = [];
                }
              }
            );
            const allData = await Promise.allSettled(promiseForData);
            // console.log("allData", allData);
            allData.map((data) => {
              if (data.value !== undefined) {
                data.value.map((info) => {
                  this.attendance.push(info);
                });
              }
            });
            this.attendance.sort((a, b) => a.rollNumber - b.rollNumber)
            // console.log("this.attendance12334", this.attendance);
            this.attendance.forEach((student) => {
              this.studentsMapping[student.uId] = student;
              this.applicableStd.push(student.uId);
            });
          }
        }
      } else {
        const semesterUserObject = {
          instituteId: this.prop_lecture.instituteId,
          semId: this.prop_lecture.semId,
          subjectId: this.prop_lecture.subjectId
        };
        // console.log('semesterUserObject',semesterUserObject)
        try {
          const getSemisterUsers =
            await this.semesterUserRepositoryInstance.getSemesterUsersOfASubject(
              semesterUserObject
            );
          const semUsers = []
          for (let i = 0; i < getSemisterUsers.length; i++) {
            semUsers.push(getSemisterUsers[i].uId);
          }
          const userObject = {
            uIds: semUsers
          };
          const getFullNamesOfUsers =
            await this.userRepositoryInstance.getFullNameOfUsers(userObject);
          for (let i = 0; i < getSemisterUsers.length; i++) {
            for (let j = 0; j < getFullNamesOfUsers.length; j++) {
              if (getSemisterUsers[i].uId === getFullNamesOfUsers[j].uId) {
                getSemisterUsers[i].name =
                  getFullNamesOfUsers[j].fullName;
                getSemisterUsers[i].totalAttendedLectures = 0
              }
            }
          }

          for (let i = 0; i < getSemisterUsers.length; i++) {
            let shouldPush = true;
            const semisterUser = getSemisterUsers[i];

            for (let j = 0; j < this.groupStudentsForAttendence.length; j++) {
              const groupStudent = this.groupStudentsForAttendence[j];

              if (groupStudent.uId === semisterUser.uId) {
                const assignedGroupsForSubject = semisterUser.assignedGroupForSubject;

                for (let k = 0; k < assignedGroupsForSubject.length; k++) {
                  const assignedGroup = assignedGroupsForSubject[k];

                  if (
                    assignedGroup.groupName === groupStudent.assignedGroupForSubject.groupName &&
                    assignedGroup.groupId === groupStudent.assignedGroupForSubject.groupId
                  ) {
                    shouldPush = false;
                    break;
                  }
                }

                if (!shouldPush) {
                  break;
                }
              }
            }

            if (shouldPush) {
              const isStudentInGroupForSubject = this.prop_lecture.groupForSubject.some(group =>
                semisterUser.assignedGroupForSubject.some(assignedGroup =>
                  group.groupName === assignedGroup.groupName &&
                  group.groupId === assignedGroup.groupId
                )
              );

              if (isStudentInGroupForSubject) {
                this.groupStudentsForAttendence.push(semisterUser);
              }
            }
          }

          // console.log('this.groupStudentsForAttendence', this.groupStudentsForAttendence)

          const lectureObject = {
            instituteId: this.prop_lecture.instituteId,
            semId: this.prop_lecture.semId,
            subjectId: this.prop_lecture.subjectId,
            uId: this.prop_lecture.uId
          }

          const getLecturesOfFacultyOfSubject =
            await this.lectureRepositoryInstance.getLecturesOfAFacultyOfASubject(lectureObject)

          // console.log('getLecturesOfFacultyOfSubject', getLecturesOfFacultyOfSubject)

          for (let i = 0; i < getLecturesOfFacultyOfSubject.length; i++) {
            const groupForSubjectString = JSON.stringify(getLecturesOfFacultyOfSubject[i].groupForSubject);
            const propGroupForSubjectString = JSON.stringify(this.prop_lecture.groupForSubject);
            // console.log(getLecturesOfFacultyOfSubject[i].isConducted)
            if (
              getLecturesOfFacultyOfSubject[i].isConducted &&
              groupForSubjectString === propGroupForSubjectString
            ) {
              // console.log('this.prop_lecture.groupForSubject', propGroupForSubjectString, groupForSubjectString)
              this.totalLecturesForGroups.push(getLecturesOfFacultyOfSubject[i]);
            }
          }
          // console.log('this.totalLecturesForGroups', this.totalLecturesForGroups)
          this.groupStudentsForAttendence.totalConductedLectures = this.totalLecturesForGroups.length
          this.totalConductedLecturesForGroups = this.totalLecturesForGroups.length

          // console.log('this.totalLecturesForGroups', this.groupStudentsForAttendence.totalConductedLectures)

          for (let i = 0; i < getSemisterUsers.length; i++) {
            for (let j = 0; j < this.groupStudentsForAttendence.length; j++) {
              if (getSemisterUsers[i].uId === this.groupStudentsForAttendence[j].uId) {
                // console.log('getSemisterUsers[i]', getSemisterUsers[i])
                getSemisterUsers[i].rollNumber ? this.groupStudentsForAttendence[j].rollNumber = getSemisterUsers[i].rollNumber : this.groupStudentsForAttendence[j].rollNumber = "-"
              }
            }
          }

          for (let i = 0; i < this.groupStudentsForAttendence.length; i++) {
            this.groupStudentsForAttendence[i].totalAttendedLectures = 0;
            if (this.groupStudentsForAttendence[i].rollNumber === "") this.groupStudentsForAttendence[i].rollNumber = "-"
            // console.log('this.groupStudentsForAttendence', this.groupStudentsForAttendence[0].rollNumber)
            for (let j = 0; j < this.totalLecturesForGroups.length; j++) {
              if (this.totalLecturesForGroups[j].present.includes(this.groupStudentsForAttendence[i].uId)) {
                this.groupStudentsForAttendence[i].totalAttendedLectures++;
              }
            }
            this.groupStudentsForAttendence[i].percentage = ((this.groupStudentsForAttendence[i].totalAttendedLectures / this.totalConductedLecturesForGroups) * 100).toFixed(2)
            if (isNaN(this.groupStudentsForAttendence[i].percentage)) {
              this.groupStudentsForAttendence[i].percentage = (0).toFixed(2);
            }
          }
          // console.log('this.groupStudentsForAttendence', this.groupStudentsForAttendence)
          this.attendance = this.groupStudentsForAttendence
          for (let i = 0; i < this.attendance.length; i++) {
            for (let j = 0; j < this.prop_lecture?.remarkForPresentStudents.length; j++) {
              if (this.attendance[i].uId === this.prop_lecture?.remarkForPresentStudents[j].uId) {
                this.attendance[i].remarkForPresentStudents = this.prop_lecture?.remarkForPresentStudents[j].remark
              }
            }
          }
          this.attendance.sort((a, b) => a.rollNumber - b.rollNumber)
        } catch (err) {
          console.log("1");
          console.log(err);
        }
      }

      this.checkAndLoadPreviousAttendance(this.prop_lecture.present);
      await Promise.all([
        this.getConductedLecturesCountOfASubject(),
        this.getTotalAttendedLecturesForUsers(),
        this.addEntryForSemesterUsers()
      ]);

      this.attendance = this.attendance.slice();
      this.isLoadingData = false;
    },
    async addEntryForSemesterUsers() {
      await getNamesOfUsers(
        this.attendance,
        "name",
        this.userRepositoryInstance
      );
    },
    async getFullNameOfUser(indexOfSemesterUserInAttendance) {
      try {
        if (this.attendance[indexOfSemesterUserInAttendance].uId) {
          this.attendance[indexOfSemesterUserInAttendance].name =
            await this.userRepositoryInstance.getFullNameOfUser({
              uId: this.attendance[indexOfSemesterUserInAttendance].uId
            });
        }
      } catch (err) {
        console.log(err);
        this.attendance[indexOfSemesterUserInAttendance].name = "";
      }
    },
    async getTotalAttendedLecturesForUsers() {
      if (!this.IsgroupedLecture) {
        try {
          const objToPush = {
            instituteId: this.prop_lecture.instituteId,
            semId: this.prop_lecture.semId,
            department: this.prop_lecture.department,
            courseYear: this.prop_lecture.courseYear,
            division: this.prop_lecture.division,
            batch: this.prop_lecture.batch || "",
            subjectId: this.prop_lecture.subjectId,
            uId: this.prop_lecture.uId
          };
          const studentsList =
            await this.lectureRepositoryInstance.getTotalAttendedLecturesForADivisionForSubject(
              objToPush
            );
          // console.log('objToPush', objToPush)
          for (let i = 0; i < this.attendance.length; i++) {
            this.attendance[i].totalAttendedLectures =
              studentsList[this.attendance[i].uId] || 0;
            if (this.totalConductedLectures > 0) {
              this.attendance[i].percentage = (
                (parseInt(this.attendance[i].totalAttendedLectures) /
                  this.totalConductedLectures) *
                100
              ).toFixed(2);
            } else {
              this.attendance[i].percentage = 0;
            }
          }
        } catch (err) {
          console.log(err);
        }
      }
    },
    async getConductedLecturesCountOfASubject() {
      if (!this.IsgroupedLecture) {
        try {
          const objToPush = {
            instituteId: this.prop_lecture.instituteId,
            semId: this.prop_lecture.semId,
            department: this.prop_lecture.department,
            courseYear: this.prop_lecture.courseYear,
            division: this.prop_lecture.division,
            batch: this.prop_lecture.batch || "",
            subjectId: this.prop_lecture.subjectId,
            uId: this.userData.uId
          };
          // console.log('getConductedLecturesCountOfASubject ', objToPush)
          this.totalConductedLectures =
            await this.lectureRepositoryInstance.getConductedLecturesCountOfASubject(
              objToPush
            );
          // console.log('this.totalConductedLectures', this.totalConductedLectures)
          for (let i = 0; i < this.attendance.length; i++) {
            this.attendance[i].rollNumber = this.attendance[i].rollNumber ? this.attendance[i].rollNumber : "-";
            this.attendance[i].totalAttendedLectures = this.attendance[i].totalAttendedLectures ? this.attendance[i].totalAttendedLectures : 0;
            this.attendance[i].percentage = this.attendance[i].percentage ? this.attendance[i].percentage : (0).toFixed(2);
          }
        } catch (err) {
          console.log(err);
          this.totalConductedLectures = 0;
        }
      }
    },
    checkAndLoadPreviousAttendance(presentStudentsList) {
      if (!this.IsgroupedLecture) {
        const semesterUserObjectsOfPresentStudents = this.attendance.filter(
          (studentObject) => presentStudentsList.includes(studentObject.uId)
        );
        this.selection = semesterUserObjectsOfPresentStudents;
        // console.log(this.selection)
      } else {
        const semesterUserObjectsOfPresentStudents = this.groupStudentsForAttendence.filter(
          (studentObject) => this.prop_lecture.present.includes(studentObject.uId)
        );
        this.selection = semesterUserObjectsOfPresentStudents;
      }
    },
    async submitAttendance() {
      if (!this.IsgroupedLecture) {
        this.prop_lecture.remarkForPresentStudents = []
        this.attendance.forEach((student) => {
          if (student?.remarkForPresentStudents) {
            this.prop_lecture.remarkForPresentStudents.push({
              uId: student.uId,
              remark: student?.remarkForPresentStudents
            })
          }
        })
        const objToPush = JSON.parse(JSON.stringify(this.prop_lecture));
        objToPush.present = [];
        objToPush.absent = [];
        // console.log('this.selection', this.selection)
        this.attendance.forEach((student) => {
          const isSelected = this.selection.some((selectedStudent) => selectedStudent.uId === student.uId);
          if (isSelected) {
            objToPush.present.push(student.uId);
          } else {
            objToPush.absent.push(student.uId);
          }
        });
        // console.log("objToPushSubmit", objToPush);
        try {
          const response = await this.lectureRepositoryInstance.saveAttendance(
            objToPush
          );
          // showStatus('Attendance saved successfully', 1000, 'success', this)
          // await this.fetchTopicsOfALecture()
          console.log('response', response);
          await this.backupQrData()
        } catch (err) {
          console.log(err);
        }
        // auth.deleteDocumentFromFirebase(this.prop_lecture.lectureId);
        this.$store.commit("liveData/set_tokenForQr", "");
        this.$store.commit("liveData/set_studentAttendance", []);
        this.$store.commit("liveData/set_proxyData", []);
      } else {
        this.prop_lecture.remarkForPresentStudents = []
        this.attendance.forEach((student) => {
          if (student?.remarkForPresentStudents) {
            this.prop_lecture.remarkForPresentStudents.push({
              uId: student.uId,
              remark: student?.remarkForPresentStudents
            })
          }
        })
        const objToPush = JSON.parse(JSON.stringify(this.prop_lecture));
        objToPush.present = [];
        objToPush.absent = [];
        // console.log('this.selection', this.selection)
        this.groupStudentsForAttendence.forEach((student) => {
          const isSelected = this.selection.some((selectedStudent) => selectedStudent.uId === student.uId);
          if (isSelected) {
            objToPush.present.push(student.uId);
          } else {
            objToPush.absent.push(student.uId);
          }
        });
        // console.log("objToPushSubmit", objToPush);
        try {
          const response = await this.lectureRepositoryInstance.saveAttendance(
            objToPush
          );
          // showStatus('Attendance saved successfully', 1000, 'success', this)
          // await this.fetchTopicsOfALecture()
          await this.backupQrData()
          console.log(response);
        } catch (err) {
          console.log(err);
        }
        // auth.deleteDocumentFromFirebase(this.prop_lecture.lectureId);
        this.$store.commit("liveData/set_tokenForQr", "");
        this.$store.commit("liveData/set_studentAttendance", []);
        this.$store.commit("liveData/set_proxyData", []);
      }
    },
    async fetchTopicsOfALecture() {
      // console.log('topic')
      const objToPush = {
        instituteId: this.prop_lecture.instituteId,
        semId: this.prop_lecture.semId,
        lectureId: this.prop_lecture.lectureId
      };
      // console.log(objToPush)
      try {
        const response = await this.topicRepositoryInstance.getTopicOfALecture(
          objToPush
        );
        // console.log('topic##')
        this.showTopicsOfLecture = true;
        this.topicsOfLecture = response;
        // console.log(response)
      } catch (err) {
        console.log(err);
      }
    },
    async handleOnClickTopic(topic) {
      this.loading = true;
      try {
        const objToPush = {
          instituteId: topic.instituteId,
          semId: topic.semId,
          topicId: topic.topicId,
          lectureId: topic.lectureId
        };
        if (topic.isImplemented) {
          await this.topicRepositoryInstance.markAsUnimplemented(objToPush);
        } else {
          await this.topicRepositoryInstance.markAsImplemented(objToPush);
        }
        topic.isImplemented = !topic.isImplemented;
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    async gotoactivityMyTimetable() {
      this.$router.push({
        name: "activityMyTimetable"
      });
    },
    async getOnlineLectureAttendance() {
      this.loading = true;
      this.showOnlineAttendance = false;
      try {
        const res = await apiV3.getRequest("/meeting/attendance", {
          lectureId: this.prop_lecture.lectureId
        });
        this.lectureDuration = Math.round(res.totalDuration / 60);
        this.threshold = this.lectureDuration * 0.8;
        this.presentStudentsList = res.attendanceList.filter(
          (entry) => entry.moderator === false
        );
        const tempArr = [];
        this.attendance.forEach((stud) => {
          const students = this.presentStudentsList.filter(
            (stud2) => stud2.ext_user_id === stud.uId
          );
          if (students.length > 0) {
            const studentDuration = students.reduce(
              (accu, elem) => (accu > elem.duration ? accu : elem.duration),
              0
            );
            const student = {
              uId: students[0].ext_user_id,
              duration: Math.round(studentDuration / 60)
            };
            if (student) {
              tempArr.push(student);
            }
          }
        });
        this.presentStudentsList = tempArr;
        this.processStudents();
      } catch (err) {
        this.loading = false;
        if (err.response.status === 404) {
          showStatus(
            "If lecture was conducted, data is processing....",
            6000,
            "info",
            this
          );
          return;
        }
        showStatus(
          "An unknown error occured, try later or contact admin",
          6000,
          "error",
          this
        );
        console.error(err);
      }
    },
    applyLimit(limit) {
      this.processStudents();
    },
    processStudents() {
      this.onlineStudents = [];
      this.presentStudentsList.forEach((student) => {
        if (student.duration >= Math.round(this.threshold)) {
          const studObj = this.studentsMapping[student.uId];
          if (studObj) {
            this.onlineStudents.push({
              uId: student.uId,
              duration: student.duration,
              name: studObj.name
            });
          }
        }
      });
      this.showOnlineAttendance = true;
      this.loading = false;
    },
    async saveOnlineLectureAttendance() {
      this.checkAndLoadPreviousAttendance(
        this.onlineStudents.map((stud) => {
          return stud.uId;
        })
      );
      this.showOnlineAttendance = false;
    },
    async generateQR() {
      this.$store.commit("liveData/set_studentAttendance", []);
      this.$store.commit("liveData/set_proxyData", []);
      this.$store.commit("liveData/set_studentAttendance", this.selection);

      try {
        const tokenForQr = uuidv4();
        this.$store.commit("liveData/set_tokenForQr", tokenForQr);
        const data = {
          instituteId: this.prop_lecture.instituteId,
          semId: this.prop_lecture.semId,
          lectureId: this.prop_lecture.lectureId,
          token: tokenForQr
        };
        this.qrCodeData = JSON.stringify(data);
        this.showQrCode = true;
        this.qrUpdateIntervalId = setInterval(this.updateQRData, 10000);
        auth.getAttendance({
          lectureId: this.prop_lecture.lectureId,
          applicableStudent: this.attendance
        });
      } catch (err) {
        showStatus(
          "An unknown error occured, try later or contact admin",
          6000,
          "error",
          this
        );
        console.error("Error:", err);
      }
    },
    getNameById(id) {
      const arr = [...this.groupStudentsForAttendence, ...this.attendance]
      let name = ''
      arr.map((info) => {
        if (info.uId === id) {
          name = info.name
        }
      })
      return name
    },
    updateQRData() {
      const tokenForQr = uuidv4();
      this.$store.commit("liveData/set_tokenForQr", tokenForQr);
      const data = {
        instituteId: this.prop_lecture.instituteId,
        semId: this.prop_lecture.semId,
        lectureId: this.prop_lecture.lectureId,
        token: tokenForQr
      };
      this.qrCodeData = JSON.stringify(data);
    },
    async closeQrCode() {
      clearInterval(this.qrUpdateIntervalId);
      this.proxyStudentDetails = []
      this.selection = this.$store.getters["liveData/get_studentAttendance"];
      const uIdForProxy = []
      this.attData = await auth.getDocumentFromFirebase('qrCodeAttendance', this.prop_lecture.lectureId);
      // console.log('this.attData', this.attData);
      this.keys = Object.values(this.attData);
      // const deviceIdArrForProxy = []
      // console.log('this.keys', this.keys);
      if (this.keys.length > 0) {
        this.keys.map((data) => {
          if (data.status === 5) {
            const arrForproxy = []
            this.keys.map((item) => {
              if (data.deviceId === item.deviceId) {
                if (!uIdForProxy.includes(item.uId)) {
                  // this.proxyStudentDetails.push(this.getNameById(item.uId))
                  arrForproxy.push(this.getNameById(item.uId))
                  uIdForProxy.push(item.uId)
                }
              }
            })
            // console.log('arrForproxy', arrForproxy);
            if (arrForproxy.length > 0) {
              this.proxyStudentDetails.push([...arrForproxy])
            }
          }
        })
      }
      // console.log('this.proxyStudentDetails', this.proxyStudentDetails);

      await this.submitAttendance()
      this.showQrCode = false;
      if (this.proxyStudentDetails.length > 0) {
        this.proxyDialog = true;
      }
    },
    markAbsentForProxy() {
      const dummy = []
      let arrOfProxyUid = []
      this.proxyStudentDetails.map((data) => {
        arrOfProxyUid = [...arrOfProxyUid, ...data.uIds]
      })
      this.selection.map((stu) => {
        if (!arrOfProxyUid.includes(stu.uId)) {
          dummy.push(stu)
        }
      })
      this.selection = dummy
      this.proxyDialog = false
    }
  }
};
</script>
<style scoped src="./activityTakeAttendance.css"></style>
