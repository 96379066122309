import { render, staticRenderFns } from "./activityNewCreateExam.html?vue&type=template&id=c5678290&external"
import script from "./activityNewCreateExam.vue?vue&type=script&lang=js"
export * from "./activityNewCreateExam.vue?vue&type=script&lang=js"
import style0 from "./activityNewCreateExam.css?vue&type=style&index=0&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports